<template>
  <vx-table :columns="columns" :resource="resource" :filters="filters" />
</template>

<script>
import {
  VxTable,
  VxCellLink,
  VxCellDate,
  VxCellPlayerAvatar,
  VxCellDrop,
  VxCellPercentage
} from '@/components/table'
import { passDataToResource, cases } from '@/services/resources'
import { filterTypes, sortOrders } from '@/services/table'
import { buildRoute, path } from '@/router'
import { moneyFormat } from '@/services/utils'

export default {
  name: 'CasesOpens',
  components: {
    VxTable,
    VxCellDrop,
    VxCellDate,
    VxCellLink,
    VxCellPercentage,
    VxCellPlayerAvatar
  },
  setup () {
    const resource = passDataToResource(cases.opens, {
      requestParams: {
        params: { include: 'drop,content,generation,player' }
      }
    })

    const columns = [
      {
        key: 'id',
        label: 'ID',
        sortable: true,
        defaultSort: sortOrders.desc
      },
      {
        key: 'player',
        label: 'Player Id',
        component: VxCellPlayerAvatar
      },
      {
        key: 'drop',
        label: 'Drop',
        component: VxCellDrop,
        tdAttr: {
          showPrice: true
        }
      },
      {
        key: 'chest',
        label: 'Case',
        component: VxCellLink,
        tdAttr: ({ id, title }, key, { generation }) => {
          const casePrice = generation?.chest_price
            ? `(${moneyFormat(generation.chest_price)})`
            : ''
          return {
            label: title,
            subLabel: `#${id} ${casePrice}`,
            href: buildRoute(path.cases, { query: { id } })
          }
        }
      },
      {
        key: 'content.chance',
        label: 'Drop Chance',
        component: VxCellPercentage,
        tdAttr: {
          fractionDigits: 3
        }
      },
      {
        key: 'player_roll_id',
        label: 'PF Data',
        component: VxCellLink,
        tdAttr: (id) => ({
          label: `Roll #${id}`,
          href: buildRoute(path.rolls, { query: { id } })
        })
      },
      {
        key: 'created_at',
        component: VxCellDate
      }
    ]

    const filters = [
      {
        key: 'id',
        type: filterTypes.number
      },
      {
        key: 'player_id',
        type: filterTypes.number
      },
      {
        key: 'chest_id',
        label: 'Case id',
        type: filterTypes.number
      },
      {
        key: 'drop_id',
        type: filterTypes.number
      },
      {
        key: 'item_title',
        type: filterTypes.text
      },
      {
        key: 'created_at',
        type: filterTypes.dateRangePicker,
        apiKeys: ['from', 'to']
      }
    ]

    return {
      resource,
      columns,
      filters
    }
  }
}
</script>
